.header-layout {
	.logo {
		margin: 0 auto;
		padding: 10px 0px;
		outline: none;
		background-color: transparent;
		border: none;
		cursor: pointer;
		@media (max-width: 478px) {
			/* margin: top, right, bottom, left; */
			width: 60%;
			margin: 0px 0px 0px auto;
			padding: 10px;
		}
		img {
			max-height: 36px;
			width: 100%;
			height: 100%;
			object-fit: contain;
			@media (min-width: 479px) and (max-width: 549px) {
				width: 90%;
			}
			@media (max-width: 478px) {
				width: 100%;
			}
		}
	}

	.container-large {
		position: relative;
		> .ant-menu {
			@media (min-width: 479px) {
				position: absolute;
				right: 0;
			}
		}
	}

	.ant-layout {
		padding: 0 15px;
		align-items: center;
		flex-direction: row;
		justify-content: space-between;

		.ant-menu {
			background-color: #f0f2f5;
		}
	}
}

.collapse-btn {
	display: block;
	background-color: transparent;
	border: none;

	padding: 0;

	position: relative;
	z-index: 9;
	width: 32px;
	height: 32px;
	cursor: pointer;

	span {
		position: relative;
		height: 5px;
		background-color: rgba(0, 0, 0, 0.85);
		transition: 0.3s;
		border-radius: 3px;
		width: 100%;
		margin-top: 5px;
		display: block;
		&:nth-child(1) {
			margin-top: 1px;
		}
	}

	&.open {
		span {
			&:nth-child(1) {
				transform: rotate(-45deg);
				top: 4px;
				left: 0px;
				position: relative;
			}

			&:nth-child(2) {
				transform: rotate(45deg);
				position: relative;
				top: -1px;
				left: 0px;
				margin: 0;
			}

			&:nth-child(3) {
				display: none;
			}
		}
	}

	@media (max-width: 549px) {
		margin-left: 50px;
	}
}

.burgerMenu {
	opacity: 0;
	z-index: -1;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: 0.3s linear;

	background-color: rgba(0, 0, 0, 0.85);

	&.open {
		opacity: 1;
		z-index: 1050;
	}

	.collapse-btn {
		position: absolute;
		top: 12px;
		right: 15px;

		@media (max-width: 549px) {
			top: 4px;
		}
		span {
			background-color: #fff;
		}
	}

	&-items {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		flex-wrap: nowrap;

		width: 100%;
		height: 100%;

		a {
			transition: 0.3s linear;
			padding: 10px 15px;
			width: 100%;

			text-align: center;

			color: #a2a2a2;

			font-size: 36px;
			line-height: 42px;
			font-weight: 600;
			&.active {
				color: #fff;
			}

			&:hover {
				color: #fff;
			}

			&.logout {
				color: red;
				&:hover {
					color: #ff6d03;
				}
			}
		}

		@media (max-width: 1599px) {
			flex-direction: column;
			gap: 40px;
		}

		@media (max-width: 767px) {
			gap: 20px;
			a {
				font-size: 24px;
				line-height: 28px;
			}
		}
	}
}
