@import "~antd/dist/antd.css";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

* {
	font-family: "Inter", sans-serif;
}

.container-large {
	max-width: 2000px;
	margin: 0 auto;
	width: 100%;
	padding: 0 15px;
	overflow-x: hidden;

	&.no-gut {
		@media (max-width: 991px) {
			padding: 0;
		}
	}
}

.container {
	max-width: 1400px;
	margin: 0 auto;
	width: 100%;
	padding: 0 15px;
}

.tab-block .ant-tabs-content-holder .ant-tabs-tabpane>.ant-row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.ant-space-item {
	position: relative;

	.react-tel-input .form-control {
		border: 1px solid #d9d9d9;
		border-radius: 2px;
		font-size: 16px;
		width: 100% !important;
		height: 40px;
	}
}


// Размеры
// @media (max-width: 575px) {}

// @media (min-width: 576px) {}

// @media (min-width: 768px) {}

// @media (min-width: 992px) {}

// @media (min-width: 1200px) {}

// @media (min-width: 1600px) {}

// Номер оплаты, сумма, статус, курс, дата

// * {
// 	border: 1px solid #f2f2f2;
// }