.HomePage {
	margin-top: 20px;
	padding: 0 0 90px;
	& > .container {
		padding: 0px 30px;
	}
	&__notPayments {
		padding: 0;
		width: 100%;
		height: 70vh;
		.container {
			position: relative;
			height: 100%;
		}
		&--text {
			margin-top: 40px;
			display: flex;
			justify-content: center;
			align-items: center;

			font-weight: 600;
			font-size: 24px;

			@media (max-width: 549px) {
				font-size: 18px;
				padding: 0px 15px;
			}
		}
	}
}
