.container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 100vh;
	padding-bottom: 10px;

	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: 310px;
		gap: 30px;

		&Inputs {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 15px;

			padding: 0px 15px;
		}

		&Check {
			display: flex;
			align-items: center;

			input {
				opacity: 0;
				position: absolute;
				z-index: -1;

				& + label {
					display: inline-flex;
					align-items: center;
					user-select: none;

					cursor: pointer;

					&:before {
						content: "";
						display: inline-block;
						width: 14px;
						height: 14px;
						flex-shrink: 0;
						flex-grow: 0;
						background-color: #f5f5f5;
						border: 1px solid #cdcdcd;
						border-radius: 5px;
						margin-right: 10px;
						background-repeat: no-repeat;
						background-position: center center;
						background-size: 63% 63%;
					}
				}

				&:checked + label:before {
					border-color: transparent;
					background-color: #58b71e;
					background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L10 1' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
				}
			}

			label {
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 16px;
				cursor: pointer;
			}

			span {
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 16px;
			}
		}

		&Rub {
			font-style: normal;
			font-weight: 600;
			font-size: 32px;
			line-height: 34px;
		}

		&Btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 190px;
			cursor: pointer;
			border: 1px solid #6e6e6e;
			border-radius: 7px;
			padding: 15px 13px;

			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 15px;

			letter-spacing: 0.56px;

			color: #6e6e6e;
			background-color: #fff;

			transition: 0.3s linear;

			&:hover {
				background-color: #fafafa;
				box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12), 0px 4px 4px -2px rgba(24, 39, 75, 0.08);
			}
			&Loading {
				background: repeating-linear-gradient(45deg, #c7c7c7 0%, #c7c7c7 20%, #ffffff 20%, #ffffff 40%);
				animation: load 1s linear 0s infinite;
				background-repeat: repeat-x;
			}
			@keyframes load {
				from {
					background-position-x: 0px;
				}
				to {
					background-position-x: 95px;
				}
			}

			&:disabled {
				color: #a6a6a6;
				pointer-events: none;
				border-color: transparent;
				background-color: #f5f5f5;
			}
		}

		&Installments {
			display: flex;
			flex-direction: column;
			gap: 5px;
			width: 100%;

			&Title {
				display: block;

				color: #787878;
			}
		}

		&Promocode {
			display: flex;
			align-items: center;
			background-color: #f5f5f5;
			border-radius: 7px;
			transition: 0.3s linear;
			position: relative;
			padding-top: 1px;
			padding-left: 1px;
			padding-bottom: 1px;

			input {
				border-radius: 7px 0px 0px 7px;
			}

			.formBtnPromo {
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				cursor: pointer;
				border-radius: 0px 7px 7px 0px;
				padding: 14px 10px;
				height: 100%;
				font-style: normal;
				font-weight: 500;
				font-size: 13px;
				line-height: 15px;
				border: 2px solid #f5f5f5;
				letter-spacing: 0.56px;
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 16px;
				letter-spacing: 1px;
				color: #6e6e6e;
				background-color: #fff;

				transition: 0.3s linear;

				&:hover {
					border-color: #6e6e6e;
					background-color: #fff;
				}
			}
		}
	}

	.phoneContainer {
		transition: 0.3s linear;

		.phone {
			&Input {
				transition: 0.3s linear;
				border: 1px solid transparent !important;
				background-color: #f5f5f5;
				padding-top: 14px;
				padding-bottom: 13px;
				height: 100%;

				&:focus {
					border-color: #f5f5f5 !important;
					background-color: #fff;
				}
			}

			&Button {
				border: 1px solid transparent !important;

				&:focus {
					border-color: #f5f5f5 !important;
					background-color: #fff;
				}
			}
		}
	}
}
