.container {
	width: 100%;
	position: relative;
	--spacing: 18px;

	label {
		display: none;
		position: absolute;
		left: var(--spacing);
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 1px;
		color: #787878;
	}

	.input {
		outline: none;
		display: block;
		width: 100%;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 1px;
		border: 1px solid transparent;
		background-color: #f5f5f5;
		border-radius: 7px;
		transition: 0.3s linear;
		position: relative;
		padding: 14px var(--spacing);

		&[required] + label::after {
			content: "*";
			color: red;
		}

		&::placeholder {
			color: transparent;
			pointer-events: none;
			-ms-user-select: none;
			-moz-user-select: none;
			-webkit-user-select: none;
			user-select: none;
		}

		&:placeholder-shown + label {
			display: block;
		}
	}

	&Focus {
		.input {
			border-color: #f5f5f5;
			background-color: #fff;
			color: #202020;

			& + label {
				color: #202020;
			}
		}
	}
}
