.ButtonBack {
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	border: none;
	background-color: transparent;
	cursor: pointer;
	&-icon {
		margin-right: 16px;
		font-size: 16px;
		line-height: 1;
	}

	&-title {
		color: rgba(0, 0, 0, 0.6);
		font-size: 16px;
		line-height: 1.5715;
		font-weight: 600;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
