%inputDisabled {
	cursor: default;
	color: rgba(0, 0, 0, 0.85);
}

%inputDisabledDefault {
	background-color: transparent;
	border-color: transparent !important;
}

.PersonalUserForm {
	.ant-input {
		&[disabled] {
			@extend %inputDisabled;
			&:hover {
				@extend %inputDisabled;
			}
		}
	}

	& &--title {
		margin: 0;
	}

	&__header {
		margin-bottom: 0.5em;
	}

	& &__body {
		width: 100%;
		.ant-select-disabled.ant-select:not(.ant-select-customize-input)
			.ant-select-selector {
			@extend %inputDisabled;
			color: rgba(0, 0, 0, 0.85);
		}
		&-group {
			display: flex;
			align-items: center;
			label {
				font-size: 14px;
				font-weight: 600;
				display: block;
				flex: 0 0 auto;
				width: 120px;
			}
			input {
				color: rgba(0, 0, 0, 0.85);
				flex: 1 1 auto;
				border-width: 1px;
				&:disabled {
					border: 1px solid transparent;
				}
				&.input-city {
					background-color: transparent;
				}
				&::-webkit-input-placeholder {
					/* WebKit, Blink, Edge */
					color: rgba(0, 0, 0, 0.85);
				}
				&:-moz-placeholder {
					/* Mozilla Firefox 4 to 18 */
					color: rgba(0, 0, 0, 0.85);
					opacity: 1;
				}
				&::-moz-placeholder {
					/* Mozilla Firefox 19+ */
					color: rgba(0, 0, 0, 0.85);
					opacity: 1;
				}
				&:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: rgba(0, 0, 0, 0.85);
				}
				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: rgba(0, 0, 0, 0.85);
				}
				&::placeholder {
					/* Most modern browsers support this now. */
					color: rgba(0, 0, 0, 0.85);
				}
			}
			.ant-select {
				width: 100%;
			}

			& .inputDisabled {
				@extend %inputDisabled;
				background-color: #f5f5f5;
				border-color: #d9d9d9 !important;
				&.activeDisabled {
					@extend %inputDisabledDefault;
				}
			}
		}

		&-button {
			margin-top: 10px;
			width: 100%;
		}

		.ant-btn-primary,
		.ant-btn-primary:active,
		.ant-btn-primary:focus {
			margin-top: 10px;
			margin-bottom: 10px;
			padding: 10px 25px;
			border-radius: 50px;
			height: 48px;
			color: #fff;
			background-color: #f461a1;
			border-color: #f461a1;
			&:hover {
				color: #000;
				background-color: #eeeeee;
				border-color: #eeeeee;
			}
		}

		.ant-btn-default,
		.ant-btn-default:active,
		.ant-btn-default:focus {
			margin-bottom: 10px;
			padding: 10px 25px;
			border-radius: 50px;
			height: 48px;
			color: #f461a1;
			background-color: transparent;
			border-color: #f461a1;
			&:hover {
				color: #000;
				background-color: #eeeeee;
				border-color: #eeeeee;
			}
		}
	}

	.toggleEdit {
		&--default {
			display: none;
		}
	}
}
