.webinar {
	&-photo {
		margin-bottom: 15px;
		overflow: hidden;
		color: #6b6966;
		img {
			transition: 0.3s ease;

			display: inline-block;
			vertical-align: middle;
			max-width: 100%;
			height: auto;
		}
		&:hover img {
			transform: scale(1.04);
		}
	}

	&-heading {
		color: #000;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.5;
		text-transform: uppercase;
	}

	&-start {
		margin-top: 10px;
		color: #000;
		font-size: 14px;
		font-weight: 600;
		line-height: 1;
	}

	&-btn {
		margin-top: 15px;
	}

	&-text {
		font-weight: 600;
		margin-top: 10px;
		color: #2a9d2a;
	}

	.ant-btn-primary,
	.ant-btn-primary:active,
	.ant-btn-primary:focus {
		margin-bottom: 10px;
		padding: 10px 15px;
		border-radius: 50px;
		height: 48px;
		color: #fff;
		background-color: #f461a1;
		border-color: #f461a1;
		&:hover {
			color: #000;
			background-color: #eeeeee;
			border-color: #eeeeee;
		}
	}

	.ant-btn-default,
	.ant-btn-default:active,
	.ant-btn-default:focus {
		margin-bottom: 10px;
		padding: 10px 15px;
		border-radius: 50px;
		height: 48px;
		color: #f461a1;
		background-color: transparent;
		border-color: #f461a1;
		&:hover {
			color: #000;
			background-color: #eeeeee;
			border-color: #eeeeee;
		}
	}
}
