.webinar {
	&Page {
		padding-top: 46px;
		@media (max-width: 767px) {
			padding-top: 22px;
		}
	}
	&-frame {
		position: relative;
		padding-top: 56.25%;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	&-video {
		width: 100%;
		height: 100%;
		@media (max-width: 991px) {
			height: 320px;
		}

		@media (max-width: 499px) {
			margin-top: -20px;
			height: 360px;
		}
	}

	&__prices {
		padding: 60px 0;
		.ant-row {
			margin-left: -14px !important;
			margin-right: -14px !important;
		}

		&--title {
			height: 70px;
			font-size: 20px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 30px;
		}

		&--amount {
			margin: 1.875rem 0 3rem;
			font-size: 54px;
			line-height: 1;
			@media (max-width: 1550px) {
				font-size: 50px;
			}
			@media (max-width: 1249px) {
				font-size: 46px;
			}
			&.not-discount {
				margin: 0.975rem 0;
				color: red;
				text-decoration: line-through;
			}
			&.discount {
				color: green;
			}
		}

		&--description {
			margin-bottom: 1.875rem;
			color: #acacac;
			font-size: 16px;
			font-weight: 400;
			min-height: 54px;
		}

		&-container {
			height: 100%;
			display: flex;
			flex-flow: column;
			justify-content: space-between;
			background: #f1f1f1;
			border: 2px solid #eee;
			border-radius: 40px;
			padding: 1.875rem;
			cursor: pointer;
			text-align: center;
			transition: 0.3s ease;
			&:hover {
				background-color: #d893d6;
				.webinar__prices {
					&--description {
						color: #333;
					}

					&--button {
						background-color: #ffffff;
						color: #000000;
					}
				}
			}
		}

		&--button {
			cursor: pointer;
			width: 90%;
			margin: 0 auto;
			padding: 0.9375rem;
			background: #ed2586;
			color: #ffffff;
			border-radius: 30px;
			border: 1px solid #eee;
			text-transform: uppercase;
			transition: 0.3s ease;
		}
	}
}

.webinarStart {
	margin-top: 4px;
	font-size: 12px;
	line-height: 1.5715;
	@media (max-width: 767px) {
		padding-right: 5px;
		text-align: right;
	}
	span {
		font-size: 14px;
		font-weight: 600;
	}
}

.modalContainer {
	.ant-modal-content {
		border-radius: 15px;
	}
	&Column {
		display: flex;
		flex-direction: column;
		gap: 20px;
		.btnModalOpen {
			cursor: pointer;
			width: 100%;
			margin: 0 auto;
			padding: 0.9375rem;
			background: #ed2586;
			color: #ffffff;
			border-radius: 30px;
			border: 1px solid #eee;
			text-transform: uppercase;
			transition: 0.3s ease;
			&:hover {
				opacity: 0.7;
			}
		}
		.btnModalClose {
			cursor: pointer;
			width: 100%;
			margin: 0 auto;
			padding: 0.9375rem;
			background-color: #ffffff;
			color: #000000;
			border-radius: 30px;
			border: 1px solid #000000;
			text-transform: uppercase;
			transition: 0.3s ease;
			&:hover {
				opacity: 0.7;
			}
		}
	}
}
