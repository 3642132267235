.Registration {

	&__city,
	&__vk {
		position: relative;

		.anticon {
			position: absolute;
			top: 12px;
			left: 12px;
			z-index: 1;
		}

		input {
			padding-left: 30px;
		}
	}

	&__container {
		margin-bottom: 10px;

		.ant-btn-primary,
		.ant-btn-primary:active,
		.ant-btn-primary:focus {
			margin-top: 10px;
			margin-bottom: 10px;
			padding: 10px 15px;
			border-radius: 50px;
			height: 48px;
			color: #fff;
			background-color: #f461a1;
			border-color: #f461a1;

			&:hover {
				color: #000;
				background-color: #eeeeee;
				border-color: #eeeeee;
			}
		}

		& &-divider {
			margin: 10px 0 15px;
		}

		&-info {
			display: flex;
			align-items: center;
			margin-top: -10px;
			color: black;

			&--icon {
				margin-right: 10px;
				font-size: 24px;
			}

			&--text {
				font-weight: 500;
				font-size: 12px;
				line-height: 1.3;
				letter-spacing: 0.03em;
			}
		}

		&-reg {
			padding: 0 15px;
			color: #cecece;
			text-align: center;
			font-weight: 400;
			font-size: 12px;
			line-height: 1.3;
		}

		.phoneContainer {
			transition: 0.3s linear;

			.phone {
				&Input {
					transition: 0.3s linear;
					border: 1px solid transparent !important;
					background-color: #f5f5f5;

					height: 100%;

					&:focus {
						border-color: #f5f5f5 !important;
						background-color: #fff;
					}
				}

				&Button {
					border: 1px solid transparent !important;

					&:focus {
						border-color: #f5f5f5 !important;
						background-color: #fff;
					}
				}
			}
		}
	}
}