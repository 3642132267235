.SuccessPage {
	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100vh;
	}

	&__img {
		width: 120px;
		height: 120px;
		margin-bottom: 45px;
		@media (max-width: 374px) {
			width: 90px;
			height: 90px;
			margin-bottom: 30px;
		}
		img {
			width: 100%;
			height: 100%;
		}
	}

	&__content {
		width: 100%;
		max-width: 760px;
		padding: 25px;
		background-color: #fafafa;
		border-radius: 8px;
		:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
		}
		@media (max-width: 374px) {
			padding: 20px;
		}
		&--title {
			font-size: 32px;
			@media (max-width: 374px) {
				font-size: 26px;
			}
		}
		&--top {
			font-size: 18px;
			padding-bottom: 16px;
			@media (max-width: 374px) {
				font-size: 16px;
				padding-bottom: 0;
			}
		}

		&--middle {
			font-size: 18px;
			padding-bottom: 16px;
			@media (max-width: 374px) {
				font-size: 16px;
				padding-bottom: 0;
			}
		}

		&--down {
			font-size: 18px;
			padding-bottom: 0;
			@media (max-width: 374px) {
				font-size: 16px;
			}
		}
	}
}
