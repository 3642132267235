.container {
	width: 100%;

	.input {
		outline: none;
		display: block;
		width: 100%;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 15px;
		letter-spacing: 1px;
		border: 1px solid transparent;
		background-color: #f5f5f5;
		border-radius: 4px;
		transition: 0.3s linear;
		position: relative;
		// padding: 15px 19px;
		padding: 11px 16px;
		// min-height: 58px;

		&::-webkit-input-placeholder {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			letter-spacing: 1px;
			color: #787878;
		}

		&:-moz-placeholder {
			/* FF 4-18 */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			letter-spacing: 1px;
			color: #787878;
			opacity: 1;
		}

		&::-moz-placeholder {
			/* FF 19+ */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			letter-spacing: 1px;
			color: #787878;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			/* IE 10+ */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			letter-spacing: 1px;
			color: #787878;
		}

		&::-ms-input-placeholder {
			/* Microsoft Edge */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			letter-spacing: 1px;
			color: #787878;
		}

		&::placeholder {
			/* modern browser */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 15px;
			letter-spacing: 1px;
			color: #787878;
		}
	}

	&Focus {
		.input {
			border-color: #f5f5f5;
			background-color: #fff;
			color: #202020;

			&::-webkit-input-placeholder {
				color: #202020;
			}

			&:-moz-placeholder {
				/* FF 4-18 */
				color: #202020;

			}

			&::-moz-placeholder {
				/* FF 19+ */
				color: #202020;

			}

			&:-ms-input-placeholder {
				/* IE 10+ */
				color: #202020;
			}

			&::-ms-input-placeholder {
				/* Microsoft Edge */
				color: #202020;
			}

			&::placeholder {
				/* modern browser */
				color: #202020;
			}
		}
	}
}