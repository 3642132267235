.not-found {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 80vh;
	@media (max-width: 767px) {
		align-items: flex-start;
	}
	&--title {
		font-size: 96px;
		color: #333;
		margin-bottom: 10px;
		@media (max-width: 767px) {
			display: flex;
			flex-direction: column;
			font-size: 54px;
			margin-bottom: 20px;
			line-height: 50px;
		}
		small {
			margin-left: 24px;
			font-size: 52px;
			@media (max-width: 767px) {
				margin-left: 0;
				font-size: 32px;
			}
		}
	}
	&__content {
		margin-bottom: 36px;
		p {
			@media (max-width: 767px) {
				font-size: 16px;
				line-height: 18px;
			}
			font-size: 24px;
			line-height: 26px;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
