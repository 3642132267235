.RegistrationPage {
	width: 100%;
	// min-height: 80vh;
	min-height: 100vh;
	margin: 30px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	&__heading {
		margin-bottom: 50px;
		color: #000;
		font-size: 20px;
		font-weight: 500;
		line-height: 1.2;
		text-align: center;

		span {
			display: block;
			margin-top: 10px;
			font-size: 24px;
			font-weight: 700;
			line-height: 1.3;
		}
	}

	.site-page-header {
		padding: 0 0 15px;
		width: 100%;
		max-width: 280px;
	}

	.buttonWebinar {
		cursor: pointer;
		background-color: transparent;
		border: none;
		color: #1890ff;

		&:disabled {
			color: #959595;
		}
	}

	.ant-space-vertical {
		align-items: center;

		.ant-space-item {
			width: 280px;
			display: flex;
			justify-content: center;

			&>* {
				width: 100%;
			}
		}
	}

	.ant-btn {
		width: 230px !important;
	}
}

.Registration {
	&__container {
		width: 100%;
		max-width: 280px;
	}

	&__heading {
		margin-bottom: 15px;
		padding: 0 15px;
		color: #000;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.3;
		text-align: center;

		&--level2 {
			margin: 0;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.3;
			text-align: center;
		}
	}

	&__subheading {
		max-width: 560px;
		margin-bottom: 15px;
		color: #959595;
		font-size: 16px;
		font-weight: 500;
		line-height: 1.3;
		text-align: center;
	}
}