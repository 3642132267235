.HomePage {
	& .course {
		display: none;

		&-active {
			display: block;
			height: auto;
		}

		&-photo {
			margin-bottom: 15px;
			overflow: hidden;
			color: #6b6966;

			img {
				transition: 0.3s ease;

				display: inline-block;
				vertical-align: middle;
				max-width: 100%;
				height: auto;
			}

			&:hover img {
				transform: scale(1.04);
			}
		}

		&-img-void {
			display: flex;
			padding-top: 64%;
			width: 100%;
			background: lightgray;
		}

		&-meta {
			margin-bottom: 15px;
			color: #6b6966;
			font-size: 14px;
			line-height: 1.3;
		}

		&-in-numbers {
			margin-top: 5px;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			text-transform: lowercase;
		}

		&-number {
			padding-right: 5px;
			color: #6b6966;
		}

		&-heading {
			margin-top: 15px;
			color: #000;
			font-size: 18px;
			font-weight: 700;
			line-height: 1.5;
			text-transform: uppercase;
		}

		&-start {
			margin-top: 10px;
			color: #000;
			font-size: 14px;
			font-weight: 600;
			line-height: 1;
		}

		&-city {
			color: #6b6966;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.5;
		}

		&-description {
			color: #000;
			margin-top: 15px;
			font-size: 14px;
			line-height: 1.2;
		}

		&-site-link {
			cursor: pointer;
			height: unset;
			margin-top: 10px;
			padding: 10px 15px;
			width: fit-content;
			display: block;
			border: none;
			border-radius: 50px;
			color: #fff;
			background-color: #f24884;
			text-shadow: none;
			box-shadow: none;
			transition: 0.3s ease;

			&:hover {
				background-color: #cd4edb;
			}
		}
	}
}
