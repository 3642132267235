.select {
	position: relative;
	width: 100%;

	&Button {
		outline: none;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
		width: 100%;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: 1px;
		border: 1px solid transparent;
		background-color: #f5f5f5;
		border-radius: 7px;
		transition: 0.3s linear;
		position: relative;
		padding: 14px 19px;

		&::-webkit-input-placeholder {
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 1px;
			color: #787878;
		}

		&:-moz-placeholder {
			/* FF 4-18 */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 1px;
			color: #787878;
			opacity: 1;
		}

		&::-moz-placeholder {
			/* FF 19+ */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 1px;
			color: #787878;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			/* IE 10+ */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 1px;
			color: #787878;
		}

		&::-ms-input-placeholder {
			/* Microsoft Edge */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 1px;
			color: #787878;
		}

		&::placeholder {
			/* modern browser */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: 1px;
			color: #787878;
		}

		&Active {
			background-color: #fff;
			border-color: #f5f5f5;

			.selectButtonArrow {
				transform: rotate(180deg);
			}
		}

		&Label {
			font-style: normal;
			font-weight: 500;
			font-size: 13px;
			line-height: 15px;
			letter-spacing: 0.56px;

			color: #787878;
		}

		&Arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #787878;
			width: 15px;
			height: 8px;
			transition: 0.125s linear;

			svg {
				width: 100%;
				height: 100%;
			}
		}
	}

	&List {
		display: flex;
		flex-direction: column;

		z-index: 20;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		transform: translate(0, 10px);

		background-color: #fff;

		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
		border-radius: 7px;
		transition: 0.3s linear;
		opacity: 0;
		visibility: hidden;

		&Active {
			opacity: 1;
			visibility: visible;
		}

		&Item {
			margin: 0;
			outline: none;
			border: none;

			display: flex;
			align-items: center;
			cursor: pointer;
			background-color: transparent;

			padding: 12px 20px;
			transition: 0.3s linear;

			&:first-child {
				border-radius: 7px 7px 0px 0px;
			}

			&:last-child {
				border-radius: 0px 0px 7px 7px;
			}

			&:hover {
				background-color: #f5f5f5;
			}
		}
	}
}