.authPage {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	flex-direction: column;
	.textDivider {
		color: #797979;
		text-align: center;
	}

	.ant-btn-primary,
	.ant-btn-primary:active,
	.ant-btn-primary:focus {
		padding: 10px 15px;
		border-radius: 50px;
		height: 48px;
		color: #fff;
		background-color: #f461a1;
		border-color: #f461a1;
		&:hover {
			color: #000;
			background-color: #eeeeee;
			border-color: #eeeeee;
		}
	}

	.ant-space-item {
		display: flex;
		justify-content: center;
	}

	&__container {
		.ant-space {
			width: 100%;
			&-item:last-child {
				margin-top: 25px;
			}
		}
	}

	&__img {
		max-width: 290px;
		width: 100%;
		margin-bottom: 30px;
		img {
			object-fit: contain;

			width: 100%;
			height: 100%;
		}
	}

	&Reg {
		width: 280px;
		text-align: center;
		.ant-divider {
			margin: 12px 0 9px;
		}
	}
}
.ant-modal-body p {
	margin-top: 20px;
	text-align: center;
}

.ant-modal-body .ant-select {
	width: 100%;
}

.auth-form-inner {
	max-width: 270px !important;
}
